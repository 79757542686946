
import { Container, Dimmer, Divider, Form, Grid, Header, Loader, Message, Segment, Select } from "semantic-ui-react";
import { ResponsiveContainer } from "../../components/DefaultResponsiveContainer";
import { useCallback, useEffect, useRef, useState } from "react";
import { PhoneInput } from "react-international-phone";
import { BusinessCreationDataUpdate, BusinessCreationRequest, ValidFieldsBusinessCreationDataUpdate } from "../../models/models";
import { requestBusinessSubmission } from "../../api/firebase";
import * as Sentry from "@sentry/react";

const countryOptions = [
    { key: "rw", value: "rw", text: "Rwanda" },
];

function LocationSelector({ onValueChanged, value }: { onValueChanged: (country: string) => void, value?: string }) {
    return (
        <Segment basic>
            <Container>
                Where is your business based ?
            </Container>
            <Container>
                <Select
                    value={value}
                    placeholder='Select your country'
                    options={countryOptions}
                    onChange={(e, { value }) => onValueChanged(value as string)}
                />
            </Container>
        </Segment>
    )
}

function RwandaBasedBusinessForm() {
    const [object, setObject] = useState<BusinessCreationRequest | any>({});
    const [errors, setErrors] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [positive, setPositive] = useState("");
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        const abortController = new AbortController();
        if (abortController.signal.aborted) return;
        setObject((prev: BusinessCreationRequest) => ({ ...prev, [ValidFieldsBusinessCreationDataUpdate.RegisteredCountry]: "Rwanda" }));
        if (abortController.signal.aborted) return;
        setObject((prev: BusinessCreationRequest) => ({ ...prev, [ValidFieldsBusinessCreationDataUpdate.BusinessType]: "Freelance" }));
        return () => {
            abortController.abort();
        }
    }, []);

    const onSubmit = useCallback(async () => {
        setLoading(() => true);
        setErrors(() => []);
        setPositive(() => "");
        try {
            const response = await requestBusinessSubmission(object)
            if (response.status === "success") {
                setPositive(response.message);
                setObject(() => { });
                setSubmitted(true);
            } else {
                setErrors([response.message]);
                if (response.data && response.data.errors) {
                    const errorsReturned = response.data.errors;
                    setErrors((prev_errors) => [...prev_errors, ...errorsReturned]);
                }
            }
        } catch (error) {
            Sentry.captureException(error);
            setErrors((prev) => [...prev, (error as Error).message]);
        } finally {
            setLoading(() => false);
        }

    }, [object]);

    if (submitted) return (
        <Segment basic>
            <Dimmer active={loading}>
                <Loader size='massive'>Loading</Loader>
            </Dimmer>
            <Container style={{ textAlign: 'left' }}>
                <Message positive>
                    <Message.Header>Thank you for submitting your business.</Message.Header>
                    <Message.List>
                        <Message.Item>We will review your business and get back to you.</Message.Item>
                    </Message.List>
                </Message>
            </Container>
        </Segment>
    )

    return (
        <Segment basic>
            <Dimmer active={loading}>
                <Loader size='massive'>Loading</Loader>
            </Dimmer>
            <Container style={{ textAlign: 'left' }}>
                {(errors.length > 0) &&
                    <Message negative={errors.length > 0}>
                        <Message.Header>Errors</Message.Header>
                        <Message.List>
                            {errors.map((error, index) => <Message.Item key={index}>{error}</Message.Item>)}
                        </Message.List>
                    </Message>}
                <Form>
                    <Form.Field>
                        <label>Business Name</label>
                        <Form.Input
                            placeholder='Business Name'
                            onChange={(e, { value }) => setObject(
                                (prev: BusinessCreationRequest) =>
                                    ({ ...prev, [ValidFieldsBusinessCreationDataUpdate.BusinessName]: value })
                            )}
                        />
                        <p>This is the registered legal name.</p>
                    </Form.Field>
                    <Divider style={{ width: "30%" }} />
                    <Form.Field>
                        <label>Operating Location</label>
                        <Form.Input
                            placeholder='Operating Location'
                            onChange={(e, { value }) => setObject(
                                (prev: BusinessCreationRequest) =>
                                    ({ ...prev, [ValidFieldsBusinessCreationDataUpdate.OperatingLocation]: value })
                            )}
                        />
                        <p>This is can be a comma separated list of cities countries that you are operations are active. e.g: Kigali, Rwanda</p>
                    </Form.Field>
                    <Divider style={{ width: "30%" }} />
                    <Form.Field>
                        <label>Business Phone Number</label>
                        <PhoneInput
                            defaultCountry="rw"
                            onChange={(phone) => setObject(
                                (prev: BusinessCreationRequest) =>
                                    ({ ...prev, [ValidFieldsBusinessCreationDataUpdate.PhoneNumber]: phone })
                            )}
                        />
                        <p>The phone number we can use in order to verify the identity of the business.</p>
                    </Form.Field>
                    <Divider style={{ width: "30%" }} />
                    <Form.Field>
                        <label>Business Email</label>
                        <Form.Input
                            placeholder="Business Email"
                            type="email"
                            onChange={(e, { value }) => setObject(
                                (prev: BusinessCreationRequest) =>
                                    ({ ...prev, [ValidFieldsBusinessCreationDataUpdate.BusinessEmail]: value })
                            )}
                        />
                        <p>An email we will use to communicate with the business.</p>
                    </Form.Field>
                    <Divider style={{ width: "30%" }} />
                    <Form.Field>
                        <label>Business Sector</label>
                        <Form.Input
                            placeholder='Business Sector'
                            onChange={(e, { value }) => setObject(
                                (prev: BusinessCreationRequest) =>
                                    ({ ...prev, [ValidFieldsBusinessCreationDataUpdate.BusinessSector]: value })
                            )}
                        />
                        <p>A comma separated list of sectors that your business works in. e.g: Fashion, Agriculture, Real Estate</p>
                    </Form.Field>
                    <Divider style={{ width: "30%" }} />
                    <Segment basic style={{ padding: 0 }}>
                        <Grid columns={2} relaxed='very' stackable>
                            <Grid.Column>
                                <Form.Field>
                                    <label>TIN Number</label>
                                    <Form.Input
                                        placeholder='TIN Number'
                                        type="number"
                                        onChange={(e, { value }) => setObject(
                                            (prev: BusinessCreationRequest) =>
                                                ({ ...prev, [ValidFieldsBusinessCreationDataUpdate.TinNumber]: value })
                                        )}
                                    />
                                    <p>This is the provided TIN Number by Rwandan authorities.</p>
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Your ID number</label>
                                    <Form.Input
                                        placeholder='Your ID number'
                                        onChange={(e, { value }) => setObject(
                                            (prev: BusinessCreationRequest) =>
                                                ({ ...prev, [ValidFieldsBusinessCreationDataUpdate.CreatorNationalId]: value })
                                        )}
                                    />
                                    <p>Your ID number is used to verify your identity.</p>
                                </Form.Field>
                            </Grid.Column>
                        </Grid>
                        <Divider className="only-web" vertical>OR</Divider>
                    </Segment>
                    <Divider style={{ width: "30%" }} />
                    <Form.Field>
                        <label>Additional Comments</label>
                        <Form.TextArea
                            placeholder='Additional Comments'
                            onChange={(e, { value }) => setObject(
                                (prev: BusinessCreationRequest) =>
                                    ({ ...prev, [ValidFieldsBusinessCreationDataUpdate.AdditionalComment]: value })
                            )}
                        />
                        <p>Any additional comments you would like to add.</p>
                    </Form.Field>
                    <Form.Button primary disabled={loading} onClick={onSubmit} style={{ marginTop: '2em', marginBottom: '4em' }}>
                        Submit
                    </Form.Button>
                </Form>
            </Container>
        </Segment>
    );
}

export function RegisterBusinessPage() {
    const [country, setCountry] = useState("");

    return (
        <ResponsiveContainer>
            <Segment basic>
                <Header as='h1'>Register Business</Header>
                <p>This page will hold your current draft for business.</p>
            </Segment>
            <LocationSelector value={country} onValueChanged={(country) => setCountry(country)} />
            {country === "rw" && <RwandaBasedBusinessForm />}
        </ResponsiveContainer>
    )
}